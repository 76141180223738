import React, { useState } from "react";
import { Col, Row, Card, Container } from "react-bootstrap";
import { connect } from "react-redux";
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import * as authActions from "../../store/actions/auth"
import { capitalize, Typography } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from "react-router-dom";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AdminImg from "../../assets/images/admin.png"
import LoginLogo from "../../assets/images/logo.svg"
import background from "../../assets/images/loginBg.jpg";
import "./login.css"

function Login({ login, user }) {
    const history = useHistory()
    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fields, setFields] = useState({
        email: "",
        password: ""
    })

    const getValue = (k, v) => setFields({ ...fields, [k]: v })

    function onSubmit(e) {
        e.preventDefault()
        setSubmit(true)
        if (fields.email && fields.password) {
            setLoading(true)
            login(
                fields,
                () => history.push('/dashboard'),
                () => setLoading(false)
            )
        }
    }
    return (


        <div id="login" className="w-100">
            <div className="content-auth">
                <form onSubmit={onSubmit}>
                    <Container>
                        <Row noGutters={0} className="loginRow no-gutters">

                            <Col xl={6} className="box-left-login d-flex align-items-center text-center" style={{ backgroundImage: `url(${background})` }}>
                                <img className="admin-login-pic m-auto"
                                    alt="admin"
                                    src={AdminImg}
                                />
                            </Col>
                            <Col xl={6}>
                                <Card className="cardLogin signin-custom-body">
                                    <Card.Body className="">
                                        <div className="login-logo">
                                            <img
                                                alt="logo"
                                                src={LoginLogo}
                                            />
                                        </div>
                                        <Card.Title className="text-center">
                                            <Typography variant="h5">
                                                Admin Sign In
                                            </Typography>
                                        </Card.Title>
                                        <Card.Body className="signup-body-box">
                                            <TextField className="login-field-style"
                                                onChange={e => getValue('email', e.target.value)}
                                                error={submit && !fields.email ? true : false}
                                                helperText={submit && !fields.email ? "Please fill the Fields" : null}
                                                id="email"
                                                label="Email"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AccountCircleIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}

                                                variant="outlined" />
                                            <TextField className="login-field-style login-pass"
                                                onChange={e => getValue('password', e.target.value)}
                                                error={submit && !fields.password ? true : false}
                                                helperText={submit && !fields.password ? "Please fill the Fields" : null}
                                                type="password"
                                                id="password"
                                                label="Password"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <VpnKeyIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="outlined" />
                                            {!user.success ? <p className="login-error-txt">{user.message}</p> : null}
                                            <LoadingButton className="signin-btn"
                                                loading={loading}
                                                type="submit"
                                                onClick={onSubmit}
                                                size="large"
                                                variant="contained"
                                                disableElevation

                                            >Sign In</LoadingButton>
                                        </Card.Body>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </form>
            </div>
        </div>
    );
}

const mapStateToProps = ({ user }) => {
    return { user };
};

export default connect(mapStateToProps, authActions)(Login);
