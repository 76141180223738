import konnect from "./axios"
import Axios from "axios"
import config from "../../Configs/Config"
import { LOGIN } from "./type"

export const login=(data,success,offLoader)=>async(dispatch)=>{
    konnect.post(`/v1/admin/auth/login`,data)
    .then((res)=>{
        localStorage.setItem('token',res.data.message.token)
        konnect.defaults.headers.common['Authorization'] ="Bearer "+res.data.message.token;
        dispatch({
            type:LOGIN,
            payload:res.data
        })
        success()
        offLoader()
    })
    .catch(({response})=>{
        dispatch({
            type:LOGIN,
            payload:response?.data
        })
        offLoader()
    })
}

export const setUser=(cb,loadingOff,showMessage)=>async(dispatch)=>{

    // konnect.interceptors.response.use((res)=>{
    //     return res
    // },(error)=>{
    //     if(error.response.status == 401){
    //         showMessage()
    //         cb()
    //     }
    // })

    konnect.interceptors.response.use((response)=>{
      if (response.status === 200) {
        return response
      }
    },(error)=>{
      if (error.response && error.response.status === 401) {
        showMessage()
        cb()
      }
      return Promise.reject(error)
    })

    const token=localStorage.getItem('token')
    
    if(token){
        konnect.defaults.headers.common['Authorization'] ="Bearer "+token;
        return konnect.post('/v1/admin/auth/adminTokenValidate')
        .then((res)=>{
            dispatch({
                type:LOGIN,
                payload:{...res.data,message:{...res.data.Data,token}}
            })
            loadingOff()
        })
        .catch((err)=>{
            loadingOff()
        })
    }else{
        loadingOff()
    }
}

export const logOut=()=>async(dispatch)=>{
    localStorage.removeItem('token')
    dispatch({
        type:LOGIN,
        payload:{}
    })
}
