export const GET_DASHBOARD = "getDashboard"
export const GET_ACCOUNTS = "getAccounts"
export const GET_ACCOUNT_DETAIL = "getAccountDetail"
export const LOGIN = "login"
export const GET_CATEGORIES = "getCategories"
export const GET_PACKAGES = "getPackages"
export const GET_ACCESS = "getAccess"
export const PACKAGE_DETAIL = "packageDetail"
export const GET_LOGS = "getLogs"
export const GET_PROMOCODES = "getPromocode"
export const GET_PROMOCODE_DETAILS = "getPromocodeDetails"
export const GET_SUBSCRIBTION = "getSubscribtion"
export const GET_APPROVED_SUBS = "getApprovedSubs"
export const GET_ADMIN_LOGS = "getAdminLogs"
export const GET_USER_CATEGORY = "getUserCategory"
export const RESET_CATEGORY = "resetCategory"
export const RESET_GLOBAL_CATE = "resetGlobalCate"
export const CLEAR_USER_CAT = "clearUserCat"
export const GET_AUTH_LIST = "getAuthList"
export const GET_COUNTRY = "getCountry"
export const SEARCH_COUNTRY = "searhCountry"
export const GET_CAMPAIGN = "getCampaign"
export const GET_BRAND_CAMPAIGN = "getBrandCampaign"
export const GET_CAMPAIGN_ONE = "getCampaignOne"
export const CLEAR_CAMPAIGN = "clearCampaign"
export const CLEAR_BRAND_CAMPAIGN = "clearBrandCampaign"
export const DELETE_CAMPAIGN = "deleteCampaign"
export const CHANGE_STATUS = "changeStatus"
export const CLEAR_ACCOUNTS = "clearAccounts"
export const GET_TRANSACTION = "getTransaction"
export const GET_BRAND_AND_INSTAGRAM = "getBrandAndInstagram"
export const GET_USER_SUBSCRIPTIONS = "getUserSubscriptions"
export const ADD_MINIMIZE = "addMinimize"
export const MINUS_MINIZE = "minusMinimize"
export const ADD_CAMPAIGN_MINIMIZE = "addCampaignMinimize"
export const MINUS_CAMPAIGN_MINIZE = "minusCampaignMinimize"
export const GET_CAMPAIGN_INFLUENCERS = "getCampaignInfluencers"
export const GET_CAMPAIGN_TRANSACTION = "getCampaignTransaction"
export const CLEAR_COM_INFLUENCER = "clearCamInfluencer"
export const GET_INFLUECNERs = "getInfluencer"
export const GET_INFLUECNERS_TABLE = "getInfluencerTable"
export const CLEAR_INFLUENCER_TABLE = "clearInfluencerTable"
export const GET_INFLUENCER_CAMPAIGN = "getInfluencerCampaign"
export const CLEAR_INFLUENCER_CAMPAIGN = "clearInfluencerCampaign"
export const GET_NOTIFICATIONS_LIST = "GET_NOTIFICATIONS_LIST"
export const GET_SALE = "GET_SALE"
export const GET_ACCOUNT_LIST = "GET_ACCOUNT_LIST"
export const TRANSACTION_TYPE = "TRANSACTION_TYPE"
export const GET_LEDGER = "GET_LEDGER"
export const GET_BALANCE = "GET_BALANCE"
export const KB_COMMISSION = "KB_COMMISSION"
export const KB_EARNING = "KB_EARNING"
export const KB_BALANCE = "KB_BALANCE"
export const BRAND_FEATURED = "BRAND_FEATURED"
export const GET_ALL_SALES = "GET_ALL_SALES"
export const PARENT_CATEGORY = "PARENT_CATEGORY"
export const ACCOUNT_CATEGORY = "ACCOUNT_CATEGORY"
export const BOOKINGS = "BOOKINGS"
export const BRAND_BOOKINGS = "BRAND_BOOKINGS"
export const TOP_BRAND = "TOP_BRAND"
export const TOP_INFLUENCER = "TOP_INFLUENCER"
export const TOP_CUSTOMER = "TOP_CUSTOMER"
export const APP_LOGS = "APP_LOGS"
export const APP_SETTING = "APP_SETTING"
export const DASHBOARD_GRAPH = "DASHBOARD_GRAPH"
export const SUB_CAT = "SUB_CAT"
export const APP_USER_LOGS = "APP_USER_LOGS"
export const GET_BANNER = "GET_BANNER"
export const GET_BANNER_PRICE = "GET_BANNER_PRICE"
export const GET_CASHBACK = "GET_CASHBACK"
export const WITHDRAWAL = "WITHDRAWAL"
export const DEPOSIT = "DEPOSIT"
export const REFUND = "REFUND"
export const GET_USERS = "GET_USERS"
export const USER_DETAIL = "USER_DETAIL"
export const ORDERS = "ORDERS"
export const STORE = "STORE"
export const PLAYBACK_URL = "PLAYBACK_URL"
export const EVENTS = "EVENTS"

export const GENDER_CATEGORY = "GENDER_CATEGORY"
export const CATEGORY = "CATEGORY"
export const SUBCATEGORY = "SUBCATEGORY"
export const REFERRAL_SALES = "REFERRAL_SALES"
export const REVIEW_SALES = "REVIEW_SALES"
export const ORDER_SALES = "ORDER_SALES"
export const MARKET_FEE = "MARKET_FEE"
export const PURCHASES = "PURCHASES"
export const REFERRAL = "REFERRAL"
export const EARNING = "EARNING"
export const PURCHASER = "PURCHASER"
export const REFERRER = "REFERRER"
export const REVIEW_SALES_INF = "REVIEW_SALES_INF"
export const SHOPIFY_CATEGORY = "SHOPIFY_CATEGORY"
export const ALL_WITHDRAW_REQUEST = "ALL_WITHDRAW_REQUEST"
export const CHANGE_WITHDRAW_STATUS = "CHANGE_WITHDRAW_STATUS"
export const FEED = "FEED"
export const VIDEO = "VIDEO"
export const ADFEED = "ADFEED"
export const VARIANT_FEED = "VARIANT_FEED"
export const FEED_STATS = "FEED_STATS"
export const FEED_USERS = "FEED_USERS"
export const VIDEO_STATS = "VIDEO_STATS"
export const REVIEWS = "REVIEWS"
export const FEES = "FEES"
export const TOTAL_SALES = "TOTAL_SALES"
export const SHIPPING = "SHIPPING"






















