import React, { Component, useEffect, useState, lazy, Suspense } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import Login from "./auth/LoginComponent";
import SkeletonLoader from "../reUsable/skeleton"
import DrawerHeader from "./drawer"
import * as authActions from "../store/actions/auth"
import { toast } from "react-toastify";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";




// const InfluencerCampaign = lazy(() => import("./influencerCampaign/campaigns/campains"))

// const Ledger = lazy(() => import("./ledger/Ledger"))
// const Transaction = lazy(() => import("./transaction/Transaction"))
// // const AccountDetail = lazy(() => import("./accountMain/AccountDetail"))
// const Packages = lazy(() => import("./package/packages"))
// // const Access = lazy(() => import("./manageAccess/Access"))
// const AddPackage = lazy(() => import("./package/AddPackage"))
// const PackageDetail = lazy(() => import("./package/PackageDetail"))
// const EditPackage = lazy(() => import("./package/EditPackage"))
const Logs = lazy(() => import("./logs/logs"))
// const CreatePromoCode = lazy(() => import("./code/CreatePromoCode"))
// const Promocodes = lazy(() => import("./code/promoCodes"))
// const EditPromo = lazy(() => import("./code/EditPromo"))
// // const Subscribtion = lazy(() => import("./subscribtion/Subscribtion"))
// // const UserCategories = lazy(() => import("./userCategory/UserCategories"))
// const BrandCampaign = lazy(() => import("./campaign/Brand"))
// const Campaign = lazy(() => import("./campaign/campaigns/campains"))
// const AuthPermission = lazy(() => import("./authPer/AuthPermission"))
// const EditCampaign = lazy(() => import("./campaign/EditCampaign"))
// const Influencer = lazy(() => import("./influencerCampaign/Influencer"))
// const FollowersRequests = lazy(() => import("./followersRequest"))

// const KBCommission = lazy(() => import("./KB/KBCommission"))
// const KBBalance = lazy(() => import("./KB/KBBalance"))
// const BrandFeatured = lazy(() => import("./BrandFeatured/BrandFeatured"))
// const Sales = lazy(() => import("./sales/Sales"))
// const AccountsSales = lazy(() => import("./accounting/SalesAccounting"))
// const CategoryPopular = lazy(() => import("./categoryPopular/CategoryPopular"))
// const FeaturedCategory = lazy(() => import("./categoryFeatured/FeaturedCategory"))
// const Top = lazy(() => import("./marketing/Top"))
// // const SubCategory = lazy(() => import("./subCategory/SubCategory"))
// // const NewCategory = lazy(() => import("./newCategory/Categories"))
// const AdPrice = lazy(() => import("./adPriceTable/AdPrice"))
// const Commission = lazy(() => import("./accounting/Commission"))
// const Cashback = lazy(() => import("./accounting/Cashback"))
// const Purchase = lazy(() => import("./accounting/Purchase"))
// const Withdrawal = lazy(() => import("./accounting/Withdrawal"))
// const Deposit = lazy(() => import("./accounting/Deposit"))
// const User = lazy(() => import("./users/User"))
// const CreateUser = lazy(() => import("./users/CreateUser"))
// const EditUser = lazy(() => import("./users/EditUser"))
// const Orders = lazy(() => import("./orders/Orders"))
// const Events = lazy(() => import("./events/Events"))

// //new reports
// const OrderReport = lazy(() => import("./reports/Orders"))
// const ReferralStatsReport = lazy(() => import("./reports/ReferralStats"))
// const MarketFeeReport = lazy(() => import("./reports/MarketFee"))
// const PayoutReport = lazy(() => import("./reports/Payout"))


// //Categories

// const AdManager = lazy(() => import("./adManager/AdManager"))

// const FeedSetting = React.lazy(() => lazyRetry(() => import('./feed/FeedSetting')));




// const DashboardComponent = lazy(() => import("./dashboard/DashboardComponent"))
// const AccountsInfluencer = lazy(() => import("./accountMain/AccountInfluencer"))
// const Country = lazy(() => import("./country/Country"))
// const Notification = lazy(() => import("./notification/Notification"))
// const AccountsBrand = lazy(() => import("./accountMain/AccountBrand"))
// const AccountsCustomer = lazy(() => import("./accountMain/AccountCustomer"))
// const AdManage = lazy(() => import("./adManage/AdManage"))
// const NotMatch = lazy(() => import("./NotMatch"))
// const AppSetting = lazy(() => import("./appSetting/AppSetting"))
// const GeneralCategory = lazy(() => import("./MainCategories/GeneralCategory/GeneralCateogry"))
// const Category = lazy(() => import("./MainCategories/Category/Category"))
// const SubCategory = lazy(() => import("./MainCategories/SubCategory/SubCategory"))
// const BrandAdd = lazy(() => import("./accountMain/components/AddBrand"))
// const BrandEdit = lazy(() => import("./accountMain/components/UpdateBrand"))
// const UserEdit = lazy(() => import("./accountMain/components/EditUser"))
// const Payout = lazy(() => import("./payout/Payout"))
// const Release = lazy(() => import("./payout/Release"))
// const BoostSettings = lazy(() => import("./boostSettings/BoostSettings"))
// const VideoBoost = lazy(() => import("./videoBoost/VideoBoost"))
// const FeedStats = lazy(() => import("./feedStats/FeedStats"))
// const VideoStats = lazy(() => import("./feedStats/VideoStats"))
// const FeedSetting = lazy(() => import("./feed/FeedSetting"))
////////////
const DashboardComponent = React.lazy(() => lazyRetry(() => import("./dashboard/DashboardComponent")))
const AccountsInfluencer = React.lazy(() => lazyRetry(() => import("./accountMain/AccountInfluencer")))
const Country = React.lazy(() => lazyRetry(() => import("./country/Country")))
const Notification = React.lazy(() => lazyRetry(() => import("./notification/Notification")))
const AccountsBrand = React.lazy(() => lazyRetry(() => import("./accountMain/AccountBrand")))
const AccountsCustomer = React.lazy(() => lazyRetry(() => import("./accountMain/AccountCustomer")))
const AdManage = React.lazy(() => lazyRetry(() => import("./adManage/AdManage")))
const NotMatch = React.lazy(() => lazyRetry(() => import("./NotMatch")))
const AppSetting = React.lazy(() => lazyRetry(() => import("./Settings/appSetting/AppSetting")))
const GeneralCategory = React.lazy(() => lazyRetry(() => import("./MainCategories/GeneralCategory/GeneralCateogry")))
const Category = React.lazy(() => lazyRetry(() => import("./MainCategories/Category/Category")))
const SubCategory = React.lazy(() => lazyRetry(() => import("./MainCategories/SubCategory/SubCategory")))
const BrandAdd = React.lazy(() => lazyRetry(() => import("./accountMain/components/AddBrand")))
const BrandEdit = React.lazy(() => lazyRetry(() => import("./accountMain/components/UpdateBrand")))
const UserEdit = React.lazy(() => lazyRetry(() => import("./accountMain/components/EditUser")))
const Payout = React.lazy(() => lazyRetry(() => import("./payout/Payout")))
const Release = React.lazy(() => lazyRetry(() => import("./payout/Release")))
const BoostSettings = React.lazy(() => lazyRetry(() => import("./Settings/boostSettings/BoostSettings")))
const VideoBoost = React.lazy(() => lazyRetry(() => import("./videoBoost/VideoBoost")))
const FeedStats = React.lazy(() => lazyRetry(() => import("./feedStats/FeedStats")))
const VideoStats = React.lazy(() => lazyRetry(() => import("./feedStats/VideoStats")))
const FeedSetting = React.lazy(() => lazyRetry(() => import("./feed/FeedSetting")))
const ManageReviews = React.lazy(() => lazyRetry(() => import("./ContentManage/ManageReviews")))
const FeesSettings = React.lazy(() => lazyRetry(() => import("./Settings/FeesSetting/FeesSettings")))
const TotalSales = React.lazy(() => lazyRetry(() => import("./salesReports/TotalSales")))
const Moderation = React.lazy(() => lazyRetry(() => import("./ContentManage/Moderation")))
const Dashboard = React.lazy(() => lazyRetry(() => import("./dashboard/Dashboard")))
const VideoSettings = React.lazy(() => lazyRetry(() => import("./Settings/videoSettings/VideoSettings")))
const PayoutSetting = React.lazy(() => lazyRetry(() => import("./Settings/payoutSetting/PayoutSetting")))
const HouseAdd = React.lazy(() => lazyRetry(() => import("./accountMain/components/AddHouse")))
const Shipping = React.lazy(() => lazyRetry(() => import("./Shipping/Shipping")))


const lazyRetry = function (componentImport) {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
        );
        // try to import the component
        componentImport().then((component) => {
            window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) { // not been refreshed yet
                window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh
        });
    });
};


function Main({ setUser, logOut, user }) {
    const [loading, setLoading] = useState(true)
    var func
    function showMessage() {
        toast.info("Session Expire")
    }
    useEffect(() => {
        setUser(logOut, () => setLoading(false), showMessage)
    }, [])

    function refreshMain(cb) {
        func = cb
    }

    if (!loading) {
        if (user?.success) {
            return (
                <>
                    <DrawerHeader refresh={() => func()}>
                        <Suspense fallback={<MainLoad />}>
                            <Switch>
                                <Route exact path="/" component={() => <Redirect to="/dashboard" />} />
                                <Route exact path="/login" component={() => <Redirect to="/dashboard" />} />
                                {/* <Route exact path="/dashboard" component={() => <DashboardComponent refresh={refreshMain} />} /> */}
                                <Route exact path="/dashboard" component={() => <Dashboard refresh={refreshMain} />} />
                                <Route exact path="/requests" component={Payout} />
                                <Route exact path="/release" component={Release} />
                                {/* <Route exact path="/payout" component={Payout} /> */}
                                <Route exact path="/department" component={GeneralCategory} />
                                <Route exact path="/category" component={Category} />
                                <Route exact path="/subCategory" component={SubCategory} />

                                <Route exact path="/brand" component={AccountsBrand} />
                                <Route exact path="/brand/add" component={BrandAdd} />
                                <Route exact path="/brand/edit/:id" component={BrandEdit} />
                                <Route exact path="/house/add" component={HouseAdd} />
                                <Route exact path="/:type/edit/:id" component={UserEdit} />
                                <Route exact path="/customer" component={AccountsCustomer} />
                                <Route exact path="/adManager" component={AdManage} />
                                <Route exact path="/notification" component={Notification} />
                                <Route exact path="/appSetting" component={AppSetting} />
                                <Route exact path="/boostSetting" component={BoostSettings} />
                                <Route exact path="/boostedVideos" component={VideoBoost} />
                                <Route exact path='/country' component={Country} />
                                <Route exact path="/influencer" component={AccountsInfluencer} />
                                <Route exact path="/house" component={() => <AccountsInfluencer type={'house'} />} />
                                <Route exact path="/reviewFeed" component={() => <FeedSetting type="review" />} />
                                <Route exact path="/showFeed" component={() => <FeedSetting type="show" />} />
                                <Route exact path="/userStats" component={FeedStats} />
                                <Route exact path="/videoStats" component={VideoStats} />
                                <Route exact path="/manageReview" component={ManageReviews} />
                                <Route exact path="/feesSetting" component={FeesSettings} />
                                <Route exact path="/videoSetting" component={VideoSettings} />
                                <Route exact path="/payoutSetting" component={PayoutSetting} />
                                <Route exact path="/totalSales" component={TotalSales} />
                                <Route exact path="/moderation" component={Moderation} />
                                <Route exact path="/shipping" component={Shipping} />
                                {/* <Route exact path="/reviewFeed" component={FeedReview} /> */}
                                {/* <Route path="/accountMain/detail/:id" component={AccountDetail} /> */}
                                <Route exact path="/logs" component={Logs} />

                                {/* <Route exact path="/test" component={AdManager} /> */}

                                {/* <Route exact path="/newCategory" component={NewCategories} /> */}
                                {/* <Route exact path="/customer" component={AccountsCustomer} />
                                <Route exact path="/brand" component={AccountsBrand} />
                                <Route exact path="/subcategories" component={SubCategory} />
                                <Route exact path="/adManager" component={AdManage} />
                                <Route exact path="/packages" component={Packages} />
                                <Route exact path="/packages/detail/:id" component={PackageDetail} />
                                <Route exact path="/packages/edit/:id" component={EditPackage} />
                                <Route exact path="/packages/add" component={AddPackage} />
                                <Route exact path="/promocodes" component={Promocodes} />
                                <Route exact path='/promocodes/create' component={CreatePromoCode} />
                                <Route exact path='/promocodes/edit/:id' component={EditPromo} />
                                <Route exact path='/authPer' component={AuthPermission} />
                                <Route exact path="/catPopular" component={CategoryPopular} />
                                <Route exact path="/orders" component={OrderReport} />
                                <Route exact path="/referral" component={ReferralStatsReport} />
                                <Route exact path="/marketfee" component={MarketFeeReport} />
                                <Route exact path="/payout" component={PayoutReport} />

                                <Route exact path="/events" component={Events} /> */}

                                {/* <Route exact path="/accountMain" component={AccountsInfluencer} /> */}
                                {/* <Route path="/accountMain/detail/:id" component={AccountDetail} /> */}
                                {/* <Route exact path="/manageAccess" component={Access} /> */}
                                {/* <Route exact path='/subscribtion' component={Subscribtion} /> */}
                                {/* <Route exact path='/userCategories' component={UserCategories} /> */}
                                {/* <Route exact path='/campaign' component={BrandCampaign} />
                                <Route exact path='/campaign/:id' component={Campaign} />
                                <Route exact path='/influencer' component={Influencer} />
                                <Route exact path='/influencerCampaign/:id' component={InfluencerCampaign} /> */}
                                {/* <Route exact path='/updateCampaign/:id' component={EditCampaign} /> */}
                                {/* <Route exact path="/followersRequests" component={FollowersRequests} /> */}
                                {/* <Route exact path="/ledger" component={Ledger} /> */}
                                {/* <Route exact path="/kbcommission" component={() => <KBCommission type='Commission' />} /> */}
                                {/* <Route exact path="/kbearning" component={() => <KBCommission type='Earning' />} /> */}
                                {/* <Route exact path="/kbbalance" component={KBBalance} /> */}
                                {/* <Route exact path="/featured" component={BrandFeatured} /> */}
                                {/* <Route exact path="/bSale" component={() => <Sales type="Brand" />} /> */}
                                {/* <Route exact path="/iSale" component={() => <Sales type="Influencer" />} /> */}
                                {/* <Route exact path="/cSale" component={() => <Sales type="Customer" />} /> */}
                                {/* <Route exact path="/catFeatured" component={FeaturedCategory} /> */}
                                {/* <Route exact path="/topBrand" component={() => <Top type="brand" />} />
                                <Route exact path="/topInfluencer" component={() => <Top type="influencer" />} />
                                <Route exact path="/topCustomer" component={() => <Top type="customer" />} /> */}
                                {/* <Route exact path="/newcategories" component={NewCategory} /> */}
                                {/* <Route exact path="/adPrice" component={AdPrice} /> */}
                                {/* <Route exact path="/sales" component={() => <AccountsSales type="Brand" from="accounting" />} />
                                <Route exact path="/purchase" component={() => <Purchase type="Brand" from="accounting" />} />
                                <Route exact path="/commission" component={Commission} />
                                <Route exact path="/cashback" component={Cashback} />
                                <Route exact path="/withdrawal" component={Withdrawal} />
                                <Route exact path="/deposit" component={Deposit} />
                                <Route exact path="/users" component={User} />
                                <Route exact path='/users/create' component={CreateUser} />
                                <Route exact path='/users/edit/:id' component={EditUser} />
                                <Route exact path='/orders' component={Orders} /> */}
                                <Route component={NotMatch} />
                            </Switch>
                        </Suspense>
                    </DrawerHeader>
                </>
            )
        } else {
            return (
                <>
                    <Route path="/" component={() => <Redirect to="/login" />} />
                    <Route exact path="/login" component={Login} />
                </>
            )
        }
    } else {
        return <SkeletonLoader />
    }

}

const mapStateToProps = ({ user }) => {
    return { user };
};

export default connect(mapStateToProps, authActions)(Main);

const MainLoad = () => {
    return (
        <div className="mainLoad">
            <SkeletonTheme color="#f0f1f2" highlightColor="#f8f9f9">
                <Skeleton height={50} count={16} />
            </SkeletonTheme>
        </div>
    )
}