const config = {
    //  base_url: process.env.NODE_ENV==="production"?process.env.REACT_APP_BASE_URL:'http://172.16.1.157:9000',
     base_url:process.env.REACT_APP_BASE_URL,
    //  base_url:"https://dev2backend.ormelive.com",
    //  base_url:"http://172.16.1.157:9090",
    //  base_url:"http://172.16.2.95:9090",
    //Party's
    // base_url: "http://172.16.1.58:9090/",
    // Araj's
    // base_url:"http://172.16.1.85:9090/",
    // base_url:"http://172.16.1.85:9090/",
};

export default config; 
